<template>
  <div>
    <v-alert
      :type="alertType"
      v-if="alertMessage"
      dismissible
      @input="closeAlert"
    >
      <span v-html="alertMessage"></span>
    </v-alert>
    <v-card
      outlined
      color="#F7F7F7"
      class="pa-2"
      v-cloak
      v-bind:class="{ dragover: isDrag }"
    >
      <v-card-actions v-if="closeable">
        <v-spacer />
        <v-btn fab small @click="handleClose">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-actions>
      <v-row
        align="center"
        @drop.prevent="addFile"
        @dragover.prevent
        @dragover.stop="dragEnter"
        @dragleave.stop="dragLeave"
        v-if="file == null"
      >
        <v-col class="text-center my-10" cols="12" sm="12">
          <p class="text-center headline-color font-weight-regular ma-0">
            Seret berkas Anda disini
          </p>
          <v-img
            class="mt-4 mb-2"
            src="@/assets/images/upload.webp"
            alt="Upload"
            aspect-ratio="5"
            contain
          />
          <v-btn
            color="primary"
            class="px-10 mt-4"
            @click="$refs.fileInput.click()"
            >CARI</v-btn
          >
          <input
            type="file"
            ref="fileInput"
            style="display:none;"
            v-on:change="changeFile"
            :accept="acceptFile()"
          />
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col class="text-center" cols="12" sm="12">
          <v-card class="mx-auto pb-5" max-width="500">
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                class="mx-2"
                fab
                dark
                small
                @click="removeFile"
              >
                <v-icon>clear</v-icon>
              </v-btn>
            </v-card-actions>
            <v-img
              class="mt-4 mb-2"
              :src="fileUrl"
              v-if="file.type.includes('image')"
              alt="Upload"
              aspect-ratio="3"
              contain
            />
            <v-img
              class="mt-4 mb-2"
              src="@/assets/images/upload.webp"
              v-else
              alt="Upload"
              aspect-ratio="5"
              contain
            />
            <p class="text-center ma-0">{{ file.name }}</p>
            <p class="text-center ma-0">{{ file.size | bytesFormat }}</p>
            <div class="mx-5 mt-5" v-if="isUpload">
              <v-progress-linear
                color="light-blue"
                height="10"
                :value="progress"
                striped
              ></v-progress-linear>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import { ACCEPT_IMPORT } from "@/common/constant";

export default {
  props: {
    closeable: { type: Boolean, required: false, default: false },
    handleClose: { type: Function, required: false },
    accept: {
      type: Array,
      required: false,
      default: () => [...ACCEPT_IMPORT.IMAGE, ...ACCEPT_IMPORT.DOCUMENT]
    }
  },
  data() {
    return {
      alertType: "info",
      alertMessage: null,
      isUpload: false,
      isDrag: false,
      progress: 0,
      file: null,
      fileUrl: null
    };
  },
  watch: {
    file(val) {
      this.$emit("on-change", val);
    }
  },
  methods: {
    dragEnter() {
      this.isDrag = true;
    },
    dragLeave() {
      this.isDrag = false;
    },
    closeAlert() {
      this.alertMessage = null;
    },
    openAlert(message, type = "info") {
      this.alertMessage = message;
      this.alertType = type;
    },
    changeFile(e) {
      if (e.target.files.length > 1) return;
      this.file = e.target.files[0];
      this.fileUrl = URL.createObjectURL(this.file);
    },
    addFile(e) {
      let droppedFiles = e.dataTransfer.files;
      if (!droppedFiles) return;
      if (droppedFiles.length > 1) {
        this.openAlert("File yang diupload tidak boleh lebih dari 1.");
        return;
      }
      console.log(droppedFiles[0].name.split(".")[1]);
      if (
        !this.accept.some(d => d.includes(droppedFiles[0].name.split(".")[1]))
      ) {
        this.openAlert(`Format file yang diupload harus ${this.acceptFile()}`);
        return;
      }
      this.file = droppedFiles[0];
      this.fileUrl = URL.createObjectURL(this.file);
    },
    removeFile() {
      this.file = null;
    },
    acceptFile() {
      let acceptFile = "";
      this.accept.map((data, index) => {
        if (index != 0) {
          acceptFile = acceptFile + ", " + data;
        } else {
          acceptFile = acceptFile + data;
        }
      });
      return acceptFile;
    }
  }
};
</script>
<style lang="scss">
.dragover {
  background-color: #bdbdbd !important;
}
</style>
